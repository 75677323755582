import SelectV2 from "components/SelectV2";
import {
  CancelButton,
  ConfirmButton,
  Container,
  Divider,
  FieldsWrapper,
  FormActionContainer,
  Header,
  Label,
  RowContainer,
  Wrapper,
} from "./styles";
import {
  RiCalendarCheckFill,
  RiCloseCircleLine,
  RiContactsLine,
  RiCoupon2Line,
  RiMailLine,
  RiUserLine,
} from "react-icons/ri";
import { useForm, Controller } from "react-hook-form";
import { Colors } from "constants/colors";
import { InputForm } from "components/Form/InputForm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PAYMENT_STATUS_MOCK, PAYMENT_TYPE_MOCK } from "./misc";
import { useSearchMyEvent } from "hooks/useSearchMyEvent";
import { useAuthTicketsByEventsId } from "services/Hook/Event/Ticket";
import { TicketEvent } from "types";
import { ModalStyled } from "pages/Financial/styles";
import { useCallback, useState } from "react";
import { doManualSale } from "services/purchases";
import axios from "axios";
import { errorNotification } from "components/Notification";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { removeSpecialCharacters } from "utils/removeSpecialCharacters";

type FormSchema = {
  eventId: string;
  ticketId: string;
  paymentStatus: string;
  paymentType: string;
  buyer: {
    name: string;
    email: string;
    document: string;
  };
};

interface ManualSaleModalProps {
  isModalVisibleManual: boolean;
  handleOkManual: () => void;
  handleCancelManual: () => void;
  handleConfirmManualSale: () => void;
}

const formSchema = yup.object().shape({
  eventId: yup.string().required("Você precisa informar um evento"),
  ticketId: yup.string().required("Você precisa informar um ingresso"),
  paymentStatus: yup
    .string()
    .required("Você precisa informar o status de pagamento"),
  paymentType: yup
    .string()
    .required("Você precisa informar o tipo de pagamento"),
  buyer: yup.object().shape({
    name: yup.string().required("Informe o nome do comprador"),
    email: yup
      .string()
      .required("Informe o e-mail do comprador")
      .email("Informe um e-mail válido"),
    document: yup.string().required("Informe o documento do comprador"),
  }),
});

const antIcon = (
  <LoadingOutlined style={{ fontSize: 18, color: Colors.white }} spin />
);

function ManualSaleModal({
  handleCancelManual,
  handleOkManual,
  isModalVisibleManual,
  handleConfirmManualSale,
}: ManualSaleModalProps) {
  const {
    register,
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
  });

  const event = watch("eventId");
  const { data, isLoading } = useSearchMyEvent();
  const { data: tickets } = useAuthTicketsByEventsId<TicketEvent[]>([event]);
  const [fetching, setFetching] = useState(false);

  const handleCancel = () => {
    reset();
    handleCancelManual();
  };

  const handleCreateManualSale = useCallback(
    async ({
      ticketId,
      buyer,
      eventId,
      paymentStatus,
      paymentType,
    }: FormSchema) => {
      try {
        setFetching(true);

        const body = {
          event_id: eventId,
          event_ticket_id: ticketId,
          payment_status: paymentStatus,
          holder_name: buyer.name,
          holder_email: buyer.email,
          holder_document: removeSpecialCharacters(buyer.document),
          payment_type: paymentType,
        };

        await doManualSale(body);

        reset();
        handleConfirmManualSale();
        handleOkManual();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          errorNotification(
            "Erro ao cadastrar o time",
            error?.response?.data?.message
          );

          return;
        }

        errorNotification(
          "Erro ao cadastrar o time",
          "Tente novamente mais tarde"
        );
      } finally {
        setFetching(false);
      }
    },
    [handleConfirmManualSale, handleOkManual, reset]
  );

  return (
    <ModalStyled
      width={1060}
      visible={isModalVisibleManual}
      onOk={handleOkManual}
      onCancel={handleCancel}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <Container onSubmit={handleSubmit(handleCreateManualSale)}>
        <Header>
          <h1>Fazer venda manual</h1>
        </Header>

        <Wrapper>
          <h2>Informações da venda</h2>

          <Divider />

          <FieldsWrapper>
            <Label>Selecione o evento e o tipo de ingresso</Label>

            <RowContainer>
              <Controller
                control={control}
                name="eventId"
                render={({ field: { onChange, value } }) => (
                  <SelectV2
                    boxIcon={<RiCalendarCheckFill color={Colors.secondary30} />}
                    icon={
                      <RiCalendarCheckFill
                        color={Colors.primary100}
                        size={18}
                      />
                    }
                    options={
                      data?.results
                        ? data.results.map((i) => ({
                            label: i.name,
                            value: i._id,
                          }))
                        : []
                    }
                    placeholder="Selecione um evento"
                    label="Evento"
                    onChange={onChange}
                    value={value}
                    disabled={isLoading}
                    error={errors?.eventId?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name="ticketId"
                render={({ field: { onChange, value } }) => (
                  <SelectV2
                    boxIcon={<RiCoupon2Line color={Colors.secondary30} />}
                    icon={<RiCoupon2Line color={Colors.primary100} size={18} />}
                    options={
                      tickets
                        ? tickets.map((i) => ({
                            label: i.name,
                            value: String(i._id),
                          }))
                        : []
                    }
                    placeholder="Selecione um ingresso"
                    label="Ingresso"
                    onChange={onChange}
                    value={value}
                    disabled={!Boolean(event)}
                    error={errors?.ticketId?.message}
                  />
                )}
              />
            </RowContainer>
          </FieldsWrapper>

          <FieldsWrapper>
            <Label>Informe o status e a forma de pagamento</Label>

            <RowContainer>
              <Controller
                control={control}
                name="paymentStatus"
                render={({ field: { onChange, value } }) => (
                  <SelectV2
                    icon={
                      <RiCalendarCheckFill
                        color={Colors.primary100}
                        size={18}
                      />
                    }
                    options={PAYMENT_STATUS_MOCK}
                    placeholder="Selecione um status"
                    label="Status do pagamento"
                    onChange={onChange}
                    value={value}
                    error={errors?.paymentStatus?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name="paymentType"
                render={({ field: { onChange, value } }) => (
                  <SelectV2
                    icon={<RiCoupon2Line color={Colors.primary100} size={18} />}
                    options={PAYMENT_TYPE_MOCK}
                    placeholder="Selecione uma forma de pagamento"
                    label="Forma de pagamento"
                    onChange={onChange}
                    value={value}
                    error={errors?.paymentType?.message}
                  />
                )}
              />
            </RowContainer>
          </FieldsWrapper>

          <Divider />

          <FieldsWrapper>
            <Label>Informações do comprador</Label>

            <RowContainer>
              <InputForm
                isFull
                icon={<RiUserLine size={14} color={Colors.secondary40} />}
                type="text"
                label="Nome do comprador"
                placeholder="Insira o nome completo do comprador..."
                {...register("buyer.name")}
                error={errors?.buyer?.name}
              />
            </RowContainer>

            <RowContainer>
              <InputForm
                isFull
                icon={<RiMailLine size={14} color={Colors.secondary40} />}
                type="text"
                label="E-mail do comprador"
                placeholder="Insira o e-mail do comprador..."
                {...register("buyer.email")}
                error={errors?.buyer?.email}
              />
              <InputForm
                isFull
                icon={<RiContactsLine size={14} color={Colors.secondary40} />}
                label="CPF do comprador"
                placeholder="Insira o seu número de CPF..."
                mask="cpf"
                {...register("buyer.document")}
                error={errors?.buyer?.document}
              />
            </RowContainer>
          </FieldsWrapper>
        </Wrapper>

        <FormActionContainer>
          <CancelButton type="button" onClick={handleCancel}>
            Cancelar
          </CancelButton>
          <ConfirmButton type="submit" disabled={fetching}>
            {fetching && <Spin spinning indicator={antIcon} />}
            Enviar
          </ConfirmButton>
        </FormActionContainer>
      </Container>
    </ModalStyled>
  );
}

export default ManualSaleModal;
