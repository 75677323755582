import { useState } from "react";
import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { Tabs } from "components/Tabs";

import {
  ButtonFilter,
  Container,
  ContainerFilters,
  ContainerItems,
  Wrapper,
} from "./styles";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ResendTicket from "pages/ResendTicket";
import {
  HEADER_BREADCRUMB_LIST,
  META_TAGS,
  TICKET_OFFICE_TABS_LIST,
} from "./misc";
import TicketOfficeView from "./components/TicketOfficeView";
import GenerateReportButton from "./components/GenerateReportButton";
import { DrawerFilterGeneral } from "components/DrawerFilterGeneral";
import { RiEqualizerLine } from "react-icons/ri";
import { Colors } from "constants/colors";
import { useFiltersContext } from "contexts/FiltersContext";
import { ModalSuccess } from "components/ModalSuccess";
import { useGenerateTicketOfficeReport } from "hooks/useGenerateTicketOfficeReport";
import { FiltersByTag } from "components/FiltersTag";
import { NeedHelpArticle } from "components/NeedHelpArticle";

ChartJS.register(ArcElement, Tooltip, Legend);

enum CURRENT_VIEW {
  TICKET_OFFICE = "TICKET_OFFICE",
  RESEND_TICKET = "RESEND_TICKET",
}

function TicketOffice() {
  const [currentView, setCurrentView] = useState(CURRENT_VIEW.TICKET_OFFICE);
  const { setIsOpenFilterDrawerGeneral } = useFiltersContext();

  const { handleGenerateReport, handleOk, handleCancel, isModalVisible } =
    useGenerateTicketOfficeReport();

  const handleView = (view: string) => {
    if (view === "Bilheteria") {
      setCurrentView(CURRENT_VIEW.TICKET_OFFICE);
    }

    if (view === "Reenviar ingresso") {
      setCurrentView(CURRENT_VIEW.RESEND_TICKET);
    }
  };

  const handleOpenFilterDrawerGeneral = () => {
    setIsOpenFilterDrawerGeneral(true);
  };

  return (
    <Wrapper>
      <Header metaTags={META_TAGS} />

      <Container>
        <ContainerItems>
          <div className="bread">
            <ListBreadcrumb data={HEADER_BREADCRUMB_LIST} />
          </div>

          <div className="main-header">
            <h1>
              {currentView === CURRENT_VIEW.TICKET_OFFICE
                ? "Bilheteria"
                : "Leads"}
            </h1>

            <div className="filter-container">
              <ButtonFilter onClick={handleOpenFilterDrawerGeneral}>
                <RiEqualizerLine size={18} color={Colors.secondary100} />
              </ButtonFilter>

              <GenerateReportButton
                onHandleGenerateButton={handleGenerateReport}
              />
            </div>
          </div>

          <ContainerFilters>
            <FiltersByTag />
          </ContainerFilters>

          <Tabs data={TICKET_OFFICE_TABS_LIST} handleView={handleView} />

          {currentView === CURRENT_VIEW.TICKET_OFFICE && <TicketOfficeView />}
          {currentView === CURRENT_VIEW.RESEND_TICKET && <ResendTicket />}

          <NeedHelpArticle />
        </ContainerItems>
      </Container>

      <ModalSuccess
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        title="Relatório gerado com sucesso"
        subtitle="O relatório está sendo processo e em instantes será enviado para o seu e-mail."
      />

      <Footer />

      <DrawerFilterGeneral />
    </Wrapper>
  );
}

export default TicketOffice;
