import PixIconSmall from "assets/PixIcon";
import { Colors } from "constants/colors";
import {
  RiBankCard2Line,
  RiBillLine,
  RiCheckboxCircleLine,
  RiGift2Line,
  RiMoneyDollarCircleLine,
  RiTimeLine,
} from "react-icons/ri";

export const EVENTS_LIST_MOCK = [
  {
    label: "Nome do evento 1",
    value: "1",
  },
  {
    label: "Nome do evento 2",
    value: "2",
  },
  {
    label: "Nome do evento 3",
    value: "3",
  },
];

export const PAYMENT_STATUS_MOCK = [
  {
    label: "Pago",
    value: "PAID",
    icon: <RiCheckboxCircleLine color="#93C77B" size={18} />,
  },
  {
    label: "Pendente",
    value: "PENDING",
    icon: <RiTimeLine color="#EFA36C" size={18} />,
  },
];

export const PAYMENT_TYPE_MOCK = [
  {
    label: "Cortesia",
    value: "cortesy",
    icon: <RiGift2Line color={Colors.primary100} size={18} />,
  },
  {
    label: "PIX",
    value: "pix",
    icon: <PixIconSmall />,
  },
  {
    label: "Cartão de Crédito",
    value: "credit-card",
    icon: <RiBankCard2Line color={Colors.primary100} size={18} />,
  },
  {
    label: "Cartão de Crédito",
    value: "credit-card",
    icon: <RiBillLine color={Colors.primary100} size={18} />,
  },
];
