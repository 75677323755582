import { MdLocalAtm } from "react-icons/md";
import { Container } from "./styles";

interface ManualSaleButtonProps {
  onClick: () => void;
}

function ManualSaleButton({ onClick }: ManualSaleButtonProps) {
  return (
    <Container onClick={onClick}>
      <MdLocalAtm />
      <span>Fazer venda manual</span>
    </Container>
  );
}

export default ManualSaleButton;
