import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { PaginationProps as AntdPaginationProps } from "antd";
import {
  ButtonNext,
  ButtonPrev,
  Container,
  ContainerItems,
  PaginationStyled,
} from "./styles";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { Colors } from "constants/colors";

interface PaginationProps {
  currentPage: number;
  onPageChange(current: number): void;
  totalCountOfRegisters?: number;
  registersPerPage?: number;
  title?: string;
}

const itemRender: AntdPaginationProps["itemRender"] = (
  _: any,
  type: string,
  originalElement: any
) => {
  if (type === "prev") {
    return (
      <ButtonPrev active>
        <BiChevronLeft size={24} color={Colors.primary100} />
        <span>Anterior</span>
      </ButtonPrev>
    );
  }
  if (type === "next") {
    return (
      <ButtonNext active>
        <span>Próximo</span>
        <BiChevronRight size={24} color={Colors.primary100} />
      </ButtonNext>
    );
  }

  return originalElement;
};

export function Pagination({
  currentPage,
  onPageChange,
  totalCountOfRegisters = 0,
  title,
  registersPerPage = 5,
}: PaginationProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const showingResults = Math.min(
    totalCountOfRegisters,
    currentPage * registersPerPage
  );

  useEffect(() => {
    setSearchParams({ page: String(currentPage) });

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: String(currentPage),
    });

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage, searchParams, setSearchParams]);

  return (
    <Container>
      <ContainerItems>
        <div className="pages">
          <h2>
            Mostrando {showingResults} de {totalCountOfRegisters} {title}
          </h2>
        </div>

        <PaginationStyled
          current={currentPage}
          onChange={onPageChange}
          total={totalCountOfRegisters}
          pageSize={registersPerPage}
          showSizeChanger={false}
          itemRender={itemRender}
          showLessItems
          defaultCurrent={1}
        />
      </ContainerItems>
    </Container>
  );
}
